import React from "react";
import {Card, Col, Row, Table} from "antd";

import 'katex/dist/katex.min.css';
import { InlineMath, BlockMath } from 'react-katex';

import ChartInfo from "../components/chartInfo";
import RowTitle from "../components/rowTitle";
import PageHeader from "../components/pageHeader";

import ReactHtmlParser from 'react-html-parser';
import reactParser from 'htmlparser2-react';

import {connect} from "react-redux";

import {download} from 'util/utils';

import {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
} from "appRedux/actions/Graph";

import {axios, path} from '../../../requestEvents/requestConfig';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting'
HC_exporting(Highcharts);

class analiseItens extends React.Component {

    constructor(props){
        super(props);
        this.state = {
            item_enunciado: '',
            item_justificativa: '',
            tabela_item_parametros: '',
            grafico_analise_distratores: '',
            grafico_curvas_tri: '',
            no_data: true,
            error: false
        }
    }
    
    componentDidMount() {
        this.props.graphChangeRoute('/analiseitens');
        this.props.graphSendUpdate(true);
    }
    
    componentDidUpdate () {
        if(this.props.updateGraphs){
            let dataSelect = this.props.dataSelect;
            let dataRoute = this.props.dataRoute;
            axios.post(path()+"/graph/", {
            dataSelect,
            dataRoute
            }).then((result) => {
            if(result.data){
                if(!result.data.error){
                    let item_enunciado = result.data.item_enunciado;
                    let item_justificativa = result.data.item_justificativa;
                    let tabela_item_parametros = result.data.tabela_item_parametros;
                    let grafico_analise_distratores = result.data.grafico_analise_distratores;
                    let grafico_curvas_tri = result.data.grafico_curvas_tri;
                    this.setState({
                        item_enunciado,
                        item_justificativa,
                        tabela_item_parametros,
                        grafico_analise_distratores,
                        grafico_curvas_tri,
                        no_data: false,
                        error: false
                    })

                }else{
                    this.setState({
                        no_data: true,
                        error: true
                    })
                }
            }else{
                this.setState({
                    no_data: true,
                    error: false
                })
            }
            }).catch((err) => {
                this.setState({
                    no_data: true,
                    error: true
                })
            })
            this.props.graphSendUpdate(false);
        }
    }

    showError = () =>{
        return (
          <Row>
            <Col span={24}>
              <Card>
                {
                  this.state.error ?
                    <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                      <i className="icon icon-error" style={{fontSize: 32}}></i>
                      <p>Nenhum resultado encontrado, por favor selecione novos dados.</p>
                    </Row>
                  :
                  <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                    <i className="icon icon-alert" style={{fontSize: 32}}></i>
                    <p>Por favor, selecione os dados a serem pesquisados.</p>
                  </Row>
                }
              </Card>
            </Col>
          </Row>
        )
    }

    mapMyOwnComponents = {
        inlinemath (node) {return <InlineMath>{node.children[0].data}</InlineMath>},
        blockmath (node) {return <BlockMath>{node.children[0].data}</BlockMath>}
    }

    renderServerHtml = (serverHtml) => {
        let parsedHtml = ReactHtmlParser(serverHtml, {
            transform: (node) => {
                if(node.name in this.mapMyOwnComponents){
                    return this.mapMyOwnComponents[node.name](node);
                }
            }
        });
        return parsedHtml;
    }

    showGraphs = () => {
        const Column = Table.Column;

        return (
          <Row>
            <Col span={24}>
              <Card >
                <Row>
                    <Col style={{width: '100%'}}>
                        <div className="content">
                            {this.renderServerHtml(this.state.item_enunciado)}
                        </div>
                    </Col>
                </Row>
              </Card>
            </Col> 

            <Col span={24}>
                <Card >
                    <RowTitle text="Distribuição de Alternativas" />
                    <Row style={{display: 'flex', justifyContent: 'space-between'}}>
                        <Col style={{width: '45%'}}>
                            <Table dataSource={this.state.tabela_item_parametros} 
                                    style={{maxWidth: '100%'}}
                                    scroll={{ x: "100%" }}
                                    pagination={{ pageSize: 8 }}>
                                <Column
                                    title="Parâmetros"
                                    dataIndex="TX_PARAMETRO"
                                    key="TX_PARAMETRO"
                                />
                                <Column
                                    title=" "
                                    dataIndex="NU_NUMERO"
                                    key="NU_NUMERO"
                                />
                            </Table>
                            <div
                              onClick={() => {
                                      return download(this.state.tabela_item_parametros, "Segmentação_em_níveis_detalhados_de_proficiência")}}
                              className="ant-btn ant-btn-primary">
                              Download
                            </div>
                        </Col>
                        <Col style={{width: '45%'}}>
                            <HighchartsReact
                                style={{width: '100%'}}
                                highcharts={Highcharts}
                                options={this.state.grafico_analise_distratores}
                            />
                        </Col>
                    </Row>
                </Card>
            </Col>
    
            <Col span={24}>
              <Card >
                <Row>
                     <Col style={{width: '100%'}}>
                        <div className="content">
                            {this.renderServerHtml(this.state.item_justificativa)}
                        </div>
                    </Col>
                </Row>
              </Card>
            </Col>
    
          </Row>
        );
      }

    render() {
        return(
          <div>
              <PageHeader title="Análise de itens"
                    text="Essa página mostra exemplos de itens, com seus enunciados,
                    parâmetros e análises pedagógicas, para cada disciplina e ano/série"
                    icon="icon-area-chart" />
          {
            this.state.no_data ? 
              this.showError() :
              this.showGraphs()
          }
          </div>
        )
      }
}

const mapStateToProps = ({ graph }) => {
    const {dataRoute, dataSelect, dataFilter, updateGraphs} = graph;
    return {dataRoute, dataSelect, dataFilter, updateGraphs};
  };
  
  export default connect(mapStateToProps, {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
  })(analiseItens)