import React, {Component} from "react";
import {connect} from "react-redux";
import {Redirect, Route, Switch} from "react-router-dom";
import {ConfigProvider} from "antd";
import {IntlProvider} from "react-intl";

import AppLocale from "lngProvider";
import MainApp from "./MainApp";
import SignIn from "../SignIn";
import SignUp from "../SignUp";
import {setInitUrl, userSignInHash} from "../../appRedux/actions/Auth";
import qs from 'query-string'; 
import {LAYOUT_TYPE_BOXED, LAYOUT_TYPE_FRAMED, LAYOUT_TYPE_FULL, THEME_TYPE_DARK} from "../../constants/ThemeSetting";

const RestrictedRoute = ({component: Component, authUser, ...rest}) =>
  <Route
    {...rest}
    render={props =>
      authUser
        ? <Component {...props} />
        : <Redirect
          to={{
            pathname: '/signin',
            state: {from: props.location}
          }}
        />}
  />;


class App extends Component {

  componentDidMount() {
    if (this.props.initURL === '') {
      this.props.setInitUrl(this.props.history.location.pathname);
    }
  }

  setLayoutType = (layoutType) => {
    if (layoutType === LAYOUT_TYPE_FULL) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('full-layout');
    } else if (layoutType === LAYOUT_TYPE_BOXED) {
      document.body.classList.remove('full-layout');
      document.body.classList.remove('framed-layout');
      document.body.classList.add('boxed-layout');
    } else if (layoutType === LAYOUT_TYPE_FRAMED) {
      document.body.classList.remove('boxed-layout');
      document.body.classList.remove('full-layout');
      document.body.classList.add('framed-layout');
    }
  };

  render() {
    const {match, location, layoutType, locale, authUser, userType, initURL} = this.props;

    var loginToken = qs.parse(this.props.location.search, { ignoreQueryPrefix: true }).loginToken
    if(loginToken){
      this.props.userSignInHash({loginToken});
    }

    if (location.pathname === '/') {
      if (authUser === null) {
        return ( <Redirect to={'/signin'}/> );
      } else if (initURL === '' || initURL === '/' || initURL === '/signin') {
        if(userType === 'SUPER_ADMIN')
          return( <Redirect to={'/manager/userList'}/> )
        return ( <Redirect to={'/avaliacoes/visao-geral'}/> );
      } else if(initURL === '/manager/userList' && userType !== 'SUPER_ADMIN'){
        return ( <Redirect to={'/'}/> );
      } else {
        return ( <Redirect to={initURL}/> );
      }
    }
    if (location.pathname === '/manager/userList') {
      if(userType == null)
        return ( <Redirect to={'/signin'}/> );
      else if(userType !== 'SUPER_ADMIN')
        return ( <Redirect to={'/avaliacoes/visao-geral'}/> );
    }
    this.setLayoutType(layoutType);

    const currentAppLocale = AppLocale[locale.locale];
    return (
      <ConfigProvider  locale={currentAppLocale.antd}>
        <IntlProvider
          locale={currentAppLocale.locale}
          messages={currentAppLocale.messages}>

          <Switch>
            <Route exact path='/signin' component={SignIn}/>
            <Route exact path='/signup' component={SignUp}/>
            <RestrictedRoute path={`${match.url}`} authUser={authUser}
                             component={MainApp}/>
          </Switch>
        </IntlProvider>
      </ConfigProvider >
    )
  }


}

const mapStateToProps = ({settings, auth}) => {
  const {locale, layoutType} = settings;
  const {authUser, initURL, userType} = auth;
  return {locale, layoutType, authUser, userType, initURL}
};
export default connect(mapStateToProps, {
  setInitUrl, 
  userSignInHash
})(App);
