import React from "react";
import {
    Button,
    Checkbox,
    Form,
    Icon,
    Input,
    Select,
    message,
    Switch
} from "antd";
import { Link } from "react-router-dom";
import { axios, path } from '../../requestEvents/requestConfig';

const FormItem = Form.Item;
const Option = Select.Option;

class EditUserForm extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            username: '',
            email: '',
            userType: '',
            passwordType: "password",
        }
    }

    componentDidMount() {
        let user_id = this.props.match.params.id;
        axios.get(path() + "/user/" + user_id)
            .then((result) => {
                this.setState({ ...result.data });
            }).catch((err) => {
                console.error(err);
            })
    }

    togglePassword = () => {
        let passwordType = this.state.passwordType === 'password' ?
            "text" :
            "password"
        this.setState({ passwordType })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        this.props.form.validateFields((err, values) => {
            if (!err) {
                let user_id = this.props.match.params.id;
                axios.put(path() + "/user/" + user_id, { values })
                    .then((result) => {
                        this.props.history.push('/manager/userList');
                    }).catch((err) => {
                        console.error(err);
                    })
            } else {
                console.log(err);
            }
        });
    };

    render() {
        const { getFieldDecorator, setFieldsValue } = this.props.form;
        return (
            <div className="gx-app-login-wrap">
                <Link className="fake-button left" to={`/manager/userList`}>
                    <Button type="primary">
                        <Icon type="left" />
                        Voltar
                    </Button>
                </Link>
                <div className="gx-app-login-container">
                    <div className="gx-app-login-main-content">
                        <div className="gx-app-login-content" style={{ width: "100%", padding: 35 }}>
                            <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                                <h1 style={
                                    {
                                        fontSize: "2em",
                                        textAlign: "center"
                                    }
                                }>
                                    Editar Usuário <br /> ({this.state.username})
                                </h1>
                                <FormItem>
                                    {getFieldDecorator('username', {
                                        rules: [{ required: true }],
                                        initialValue: this.state.username
                                    })(
                                        <Input placeholder="Username" />
                                    )}
                                </FormItem>
                                <FormItem >
                                    {getFieldDecorator('email', {
                                        rules: [{ required: true }],
                                        initialValue: this.state.email
                                    })(
                                        <Input placeholder="Email" />
                                    )}
                                </FormItem>
                                <FormItem >
                                    {getFieldDecorator('password', {
                                        rules: [{ required: true }],
                                    })(
                                        <Input type={this.state.passwordType}
                                            placeholder="Senha" />
                                    )}
                                </FormItem>
                                <FormItem
                                    className="form-inline-row"
                                    label="Exibir senha: "
                                >
                                    <Switch onChange={this.togglePassword} />
                                </FormItem>
                                <FormItem >
                                    {getFieldDecorator('userType', {
                                        rules: [{ required: true }],
                                        initialValue: this.state.userType
                                    })(
                                        <Select placeholder="Tipo de usuário">
                                            {
                                                this.state.userType === 'SUPER_ADMIN' ?
                                                    <Option value="SUPER_ADMIN">SUPER ADMIN</Option> :
                                                    null
                                            }
                                            <Option value="REDE">REDE</Option>
                                            <Option value="GRUPO">GRUPO</Option>
                                            <Option value="ESCOLA">ESCOLA</Option>
                                        </Select>
                                    )}
                                </FormItem>
                                <FormItem>
                                    <Button type="primary" className="gx-mb-0" htmlType="submit">
                                        <span>Editar usuário</span>
                                    </Button>
                                </FormItem>
                            </Form>
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Form.create()(EditUserForm);