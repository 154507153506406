import {all, call, fork, put, takeEvery} from "redux-saga/effects";
import {
  signIn,
  signUpNewUser,
  signInWithHash
} from "../../requestEvents";
import {
  SIGNIN_USER,
  SIGNIN_USER_HASH,
  SIGNOUT_USER,
  SIGNUP_USER,
  GRAPH_CLEAR
} from "../../constants/ActionTypes";
import {showAuthMessage, showAuthSuccessMessage, userSignInSuccess, userSignOutSuccess, userSignUpSuccess} from "../../appRedux/actions/Auth";
import {graphClearData} from '../../appRedux/actions/Graph';

const createUserWithEmailPasswordRequest = async (username, email, password, userType, redes, escolas) =>
  await signUpNewUser({username, email, password, userType, redes, escolas})
    .then(authUser => authUser)
    .catch(error => error);

const signInUserWithEmailPasswordRequest = async (email, password) =>
  await signIn({email, password})
    .then(authUser => authUser)
    .catch(error => error)

const signInUserWithHashRequest = async (loginToken) =>
    await signInWithHash({loginToken})
      .then(authUser => authUser)
      .catch(error => error)

// const signOutRequest = async () =>
//   await  auth.signOut()
//     .then(authUser => authUser)
//     .catch(error => error);

function* createUserWithEmailPassword({payload}) {
  const {username, email, password, userType, redes, escolas} = payload;
  try {
    const signUpUser = yield call(createUserWithEmailPasswordRequest, username, email, password, userType, redes, escolas);
    if (signUpUser.message) {
      yield put(showAuthMessage(signUpUser.message));
    } else {
      yield put(showAuthSuccessMessage("USUÁRIO CRIADO COM SUCESSO"));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithEmailPassword({payload}) {
  const {email, password} = payload;
  try {
    const signInUser = yield call(signInUserWithEmailPasswordRequest, email, password);
    if (signInUser.message) {
      yield put(showAuthMessage("400 - Email ou senha incorretos"));
    } else {
      localStorage.setItem('user_id', signInUser.data.token);
      localStorage.setItem('redes', JSON.stringify(signInUser.data.redes));
      localStorage.setItem('escolas', signInUser.data.escolas);
      yield put(userSignInSuccess({
        authUser: signInUser.data.token, 
        userType: signInUser.data.userType
      }));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signInUserWithHash({payload}){
  const {loginToken} = payload;
  try {
    const signInUser = yield call(signInUserWithHashRequest, loginToken);
    if (signInUser.message) {
      yield put(showAuthMessage("400 - Hash Incorreta"));
    } else {
      localStorage.setItem('user_id', signInUser.data.token);
      localStorage.setItem('redes', JSON.stringify(signInUser.data.redes));
      localStorage.setItem('escolas', signInUser.data.escolas);
      yield put(userSignInSuccess({
        authUser: signInUser.data.token, 
        userType: signInUser.data.userType
      }));
    }
  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

function* signOut() {
  try {
      localStorage.removeItem('user_id');
      localStorage.removeItem('redes');
      localStorage.removeItem('escolas');
      yield put(userSignOutSuccess());
      yield put(graphClearData());

  } catch (error) {
    yield put(showAuthMessage(error));
  }
}

export function* createUserAccount() {
  yield takeEvery(SIGNUP_USER, createUserWithEmailPassword);
}

export function* signInUser() {
  yield takeEvery(SIGNIN_USER, signInUserWithEmailPassword);
}

export function* signInUserHash() {
  yield takeEvery(SIGNIN_USER_HASH, signInUserWithHash);
}

export function* signOutUser() {
  yield takeEvery(SIGNOUT_USER, signOut);
}

export default function* rootSaga() {
  yield all([fork(signInUser),
    fork(signInUserHash),
    fork(createUserAccount),
    fork(signOutUser)]);
}
