import React from "react";
import {Card, Col, Row, Table} from "antd";

import {connect} from "react-redux";

import PageHeader from "../components/pageHeader";

import {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
} from "appRedux/actions/Graph";

class downloads extends React.Component {
   
    constructor(props) {
        super(props);

        this.state = {
            visao_geral: window.downloads.visao_geral.default,
            programas_melhoria: window.downloads.programas_melhoria.default
        }
    }

    componentDidMount() {
        let visao_geral = this.state.visao_geral.map((item, index) => {
            return {
                ...item,
                acoes: (
                    <a href={item.download} >
                        <i class="icon icon-upload"></i>
                    </a>
                )
            }
        });
        let programas_melhoria = this.state.programas_melhoria.map((item, index) => {
            return {
                ...item,
                acoes: (
                    <a href={item.download} >
                        <i class="icon icon-upload"></i>
                    </a>
                )
            }
        });
        this.setState({
            visao_geral,
           programas_melhoria
        })
    }


    render() {
        const Column = Table.Column;
        return (
            <Row>
                <PageHeader title="Downloads"
                    text="Arquivos adicionais para acompanhar a leitura e
                    compreensão dos dados avaliativos"
                    icon="icon-upload" />
                <Col span={24}>
                    <Card >
                        <Row>
                        <h1 style={{width: '100%', 
                                    fontSize: '20px', 
                                    color: '#0070c0',
                                    textTransform: 'uppercase', 
                                    textAlign: 'left', 
                                    marginBottom: '50px'}}>
                        </h1>
                        </Row>
                        <Row>
                        <Col style={{width: '100%'}}>
                            <Table dataSource={this.state.visao_geral} 
                                pagination={{ pageSize: 12 }}>
                            <Column
                                title="Descrição"
                                style={{width: '50%'}}
                                dataIndex="descricao"
                                key="descricao"
                            />
                            <Column
                                title="Formato"
                                dataIndex="formato"
                                key="formato"
                            />
                            <Column
                                title="Ações"
                                dataIndex="acoes"
                                key="acoes"
                            />
                            </Table>
                        </Col>
                        </Row>
                    </Card>
                </Col>

                <Col style={{display: 'none'}} span={24}>
                    <Card >
                        <Row>
                        <h1 style={{width: '100%', 
                                    fontSize: '20px', 
                                    color: '#0070c0',
                                    textTransform: 'uppercase', 
                                    textAlign: 'left', 
                                    marginBottom: '50px'}}>
                                Programa de melhoria
                        </h1>
                        </Row>
                        <Row>
                        <Col style={{width: '100%'}}>
                            <Table dataSource={this.state.programas_melhoria} 
                                pagination={false}>
                            <Column
                                title="Descrição"
                                style={{width: '50%'}}
                                dataIndex="descricao"
                                key="descricao"
                            />
                            <Column
                                title="Formato"
                                dataIndex="formato"
                                key="formato"
                            />
                            <Column
                                title="Ações"
                                dataIndex="acoes"
                                key="acoes"
                            />
                            </Table>
                        </Col>
                        </Row>
                    </Card>
                </Col>
            </Row>
        )
    }
}

const mapStateToProps = ({ graph }) => {
    const {dataRoute, dataSelect, dataFilter, updateGraphs} = graph;
    return {dataRoute, dataSelect, dataFilter, updateGraphs};
  };
  
  export default connect(mapStateToProps, {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
  })(downloads)