import {
    GRAPH_ROUTE,
    GRAPH_SELECT,
    GRAPH_FILTER,
} from "../../constants/ActionTypes";

export const graphChangeRoute = (graphRoute) => {
    return {
        type: 'GRAPH_ROUTE',
        payload: graphRoute
    };
};

export const graphChangeSelect = (graphSelect) => {
    return {
        type: 'GRAPH_SELECT',
        payload: graphSelect
    };
};

export const graphChangeFilter = (graphFilter) => {
    return {
        type: 'GRAPH_FILTER',
        payload: graphFilter
    };
};

export const graphSendUpdate = (graphUpdate) => {
    return {
        type: 'GRAPH_UPDATE',
        payload: graphUpdate
    };
};

export const graphClearData = () => {
    return {
        type: 'GRAPH_CLEAR'
    }
}