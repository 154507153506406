import React from "react";
import {Card, Col, Row, Table} from "antd";


export default class pageHeader extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            title: props.title,
            text: props.text,
            icon: props.icon
        }
    }  

    render() {

        return (
            <Col span={24} className="page-header">
                <Card className="page-header_card">
                    <Col className="page-header_card_col">
                        <h1 className="page-header_card_col_title">
                            {this.state.title}
                        </h1>
                        <p className="page-header_card_col_text">
                            {this.state.text}
                        </p>
                        </Col>
                        <i className={this.state.icon+" icon page-header_card_icon"}></i>
                </Card>
            </Col>
        ) 
    }
}