import React from "react";
import {Route, Switch} from "react-router-dom";
import CreateUserForm from "./createUserForm";
import EditUserForm from "./editUserForm";
import UserList from "./usersList";

const Manager = ({match}) => (
    <Switch>
      <Route path={`${match.url}/createUser`} component={CreateUserForm}/>
      <Route path={`${match.url}/userList`} component={UserList}/>
      <Route path={`${match.url}/editUser/:id`} component={EditUserForm}/>
    </Switch>
  );
  
  export default Manager;
  