import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Popover } from "antd";
import { withRouter } from "react-router";
import { Link } from "react-router-dom";
import { onVerticalNavStyleChange, switchLanguage, toggleCollapsedSideNav } from "../../appRedux/actions/Setting";
import DataSelect from "components/DataSelect";
import UserInfo from "components/UserInfo";
import AppFilter from "components/AppFilter";
import {
  ABOVE_THE_HEADER,
  BELOW_THE_HEADER,
  HORIZONTAL_NAVIGATION,
  INSIDE_THE_HEADER,
  NAV_STYLE_DRAWER,
  NAV_STYLE_FIXED,
  NAV_STYLE_MINI,
  TAB_SIZE,
  VERTICAL_NAVIGATION
} from "constants/ThemeSetting";
import HorizontalNav from "./HorizontalNav";
import Auxiliary from "util/Auxiliary";

const { Header } = Layout;

class Topbar extends Component {

  constructor(props) {
    super(props);

    let redesReadOnly = false;
    let escolaReadOnly = false;
    let filterReadOnly = false;

    if (localStorage.getItem('redes')) {
      if (JSON.parse(localStorage.getItem('redes').split(",")).length < 2) {
        redesReadOnly = true;
      }
    }

    if (localStorage.getItem('escolas')) {
      if (localStorage.getItem('escolas').split(",").length < 2) {
        escolaReadOnly = true;
      }
    }

    if (redesReadOnly && escolaReadOnly) {
      filterReadOnly = true;
    }

    this.state = {
      searchText: '',
      noFilter: filterReadOnly
    };
  }

  updateSearchChatUser = (evt) => {
    this.setState({
      searchText: evt.target.value,
      path: window.location.pathname
    });
  };

  componentDidMount() {
    this.unlisten = this.props.history.listen((location, action) => {
      this.setState({
        path: location.pathname
      })
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }


  render() {
    const { locale, navStyle, horizontalNavPosition, navCollapsed, width } = this.props;
    let { verticalNavStyle } = this.props;
    if (width < TAB_SIZE && verticalNavStyle === NAV_STYLE_FIXED) {
      verticalNavStyle = NAV_STYLE_DRAWER;
    }
    return (
      <Auxiliary>
        {navStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === ABOVE_THE_HEADER && width >= TAB_SIZE ?
          <div className="gx-nav-header"><HorizontalNav /></div> : null}
        <Header
          className={`${navStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER ? 'gx-layout-header-horizontal' : ''}`}>
          {width < TAB_SIZE || (navStyle === VERTICAL_NAVIGATION && verticalNavStyle === NAV_STYLE_DRAWER) ?
            <div className="gx-linebar gx-mr-3">
              <i className="gx-icon-btn icon icon-menu"
                onClick={() => {
                  if (width <= TAB_SIZE || verticalNavStyle === NAV_STYLE_DRAWER) {
                    this.props.toggleCollapsedSideNav(!navCollapsed);
                  } else if (verticalNavStyle === NAV_STYLE_FIXED) {
                    this.props.onVerticalNavStyleChange(NAV_STYLE_MINI)
                  } else {
                    this.props.onVerticalNavStyleChange(NAV_STYLE_FIXED)
                  }
                }}
              />
            </div> : null}


          {width >= TAB_SIZE && navStyle === HORIZONTAL_NAVIGATION ? <div className="gx-site-logo gx-mr-2">
            <img src={require("assets/images/logo.png")} />
          </div> : null}

          <DataSelect />

          {width >= TAB_SIZE && navStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === INSIDE_THE_HEADER ?
            <HorizontalNav /> : null}
          <ul className="gx-header-notifications gx-ml-auto">

            {
              this.state.noFilter != true ?
                <Popover placement="bottomRight"
                  content={<AppFilter />}
                  trigger="click"
                  disabled={true}
                >
                  <li className="gx-notify">
                    <div className="gx-pointer gx-d-flex column center">
                      <i className="icon icon-filter gx-fs-xl" />
                      <span>Filtro</span>
                    </div>
                  </li>
                </Popover> :
                null
            }

            <li className="gx-notify">
             <Link to={`/avaliacoes/downloads`} style={{color: '#333333'}}> 
                  <div className="gx-pointer gx-d-flex column center">
                    <i className="icon icon-upload gx-fs-xl"/>
                    <span>Download</span>
                  </div>
              </Link>
            </li>

            <li className="gx-user-nav">
              <div className="gx-pointer gx-d-block">
                <UserInfo />
              </div>
            </li>
          </ul>
        </Header>
        {width >= TAB_SIZE && navStyle === HORIZONTAL_NAVIGATION && horizontalNavPosition === BELOW_THE_HEADER ?
          <div className="gx-nav-header gx-nav-header-ble"><HorizontalNav /></div> : null}
      </Auxiliary>
    );
  }
}

const mapStateToProps = ({ settings }) => {
  const { navStyle, verticalNavStyle, horizontalNavPosition, locale, width, navCollapsed } = settings;
  return { navStyle, verticalNavStyle, horizontalNavPosition, locale, width, navCollapsed }
};

export default withRouter(connect(mapStateToProps, { onVerticalNavStyleChange, toggleCollapsedSideNav, switchLanguage })(Topbar));
