import React from "react";
import {Route, Switch} from "react-router-dom";
import VisaoGeral from "./visaoGeral";
import MediasFaixas from "./mediasFaixas";
import Matrizes from "./matrizes";
import Downloads from "./downloads";
import AnaliseItens from "./analiseItens";
import ConsultaAlunos from "./consultaAlunos";

const Avaliacoes = ({match}) => (
    <Switch>
      <Route path={`${match.url}/visao-geral`} component={VisaoGeral}/>
      <Route path={`${match.url}/medias-faixas`} component={MediasFaixas}/>
      <Route path={`${match.url}/matrizes`} component={Matrizes}/>
      <Route path={`${match.url}/downloads`} component={Downloads}/>
      <Route path={`${match.url}/analise-itens`} component={AnaliseItens} />
      <Route path={`${match.url}/consulta-alunos`} component={ConsultaAlunos} />
    </Switch>
  );
  
  export default Avaliacoes;
  