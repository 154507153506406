import React from "react";
import { Card, Col, Row, Table } from "antd";
import { connect } from "react-redux";
import {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate,
} from "appRedux/actions/Graph";
import RowTitle from "../components/rowTitle";
import PageHeader from "../components/pageHeader";
import { axios, path } from "../../../requestEvents/requestConfig";

class consultaAlunos extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      tabela_consulta_alunos: "",
      no_data: true,
      error: false,
    };
  }

  componentDidMount() {
    this.props.graphChangeRoute("/consultaralunos");
    this.props.graphSendUpdate(true);
  }

  componentDidUpdate() {
    if (this.props.updateGraphs) {
      let dataSelect = this.props.dataSelect;
      let dataRoute = this.props.dataRoute;

      if (dataSelect.rede == null && localStorage.getItem("redes")) {
        if (JSON.parse(localStorage.getItem("redes").split(",")).length == 1) {
          dataSelect.rede = JSON.parse(localStorage.getItem("redes"))[0].rede;
        }
      }

      if (dataSelect.escola == null && localStorage.getItem("escolas")) {
        if (localStorage.getItem("escolas").split(",").length == 1) {
          dataSelect.escola = localStorage.getItem("escolas");
        }
      }

      axios
        .post(path() + "/graph/", {
          dataSelect,
          dataRoute,
        })
        .then((result) => {
          if (result.data) {
            if (!result.data.error) {
              let tabela_consulta_alunos = result.data.tabela_consulta_alunos;
              for (let i = 0; i < tabela_consulta_alunos.length; i++) {
                tabela_consulta_alunos[i].key = i + 1;
                if (tabela_consulta_alunos[i].NU_RANK_TURMA == undefined) {
                  tabela_consulta_alunos[i].NU_RANK_TURMA = "-";
                }
                let ng =
                  tabela_consulta_alunos[i].NU_NOTA_TRI_GERAL == undefined
                    ? tabela_consulta_alunos[i].NU_NOTA_TCT_GERAL
                    : tabela_consulta_alunos[i].NU_NOTA_TRI_GERAL;
                if (ng == undefined) {
                  ng = "-";
                } else {
                  ng = parseFloat(ng).toFixed(1);
                }
                tabela_consulta_alunos[i].NOTA_GERAL = ng;
                let nt =
                  tabela_consulta_alunos[i][
                    `NU_NOTA_TRI_${this.props.dataSelect.disc}`
                  ];
                if (nt == undefined) {
                  nt =
                    tabela_consulta_alunos[i][
                      `NU_NOTA_TCT_${this.props.dataSelect.disc}`
                    ];
                }
                if (nt == undefined) {
                  nt =
                    tabela_consulta_alunos[i][
                      `NU_NOTA_REDACAO`
                    ];
                }
                if (nt == undefined) {
                  nt = "-";
                } else {
                  nt = parseFloat(nt).toFixed(1);
                }
                tabela_consulta_alunos[i].NOTA_MAT = nt;
              }
              this.setState({
                tabela_consulta_alunos,
                no_data: false,
                error: false,
              });
            } else {
              this.setState({
                no_data: true,
                error: true,
              });
            }
          } else {
            this.setState({
              no_data: true,
              error: false,
            });
          }
        })
        .catch(() => {
          this.setState({
            no_data: true,
            error: true,
          });
        });
    }
    this.props.graphSendUpdate(false);
  }

  render() {
    return (
      <div>
        <PageHeader
          title="Consulta de alunos"
          text="Navegue pelos microdados para uma visão aluno a aluno"
          icon="icon-table"
        />
        {this.state.no_data ? this.showError() : this.showGraphs()}
      </div>
    );
  }

  filter = (type) => {
    var filterJson = [];
    this.state.tabela_consulta_alunos.forEach((elem) => {
      var el = filterJson.filter((e) => e.text == elem[type]);
      if (el.length == 0) {
        if (elem[type]) {
          filterJson.push({
            text: elem[type],
            value: elem[type],
          });
        }
      }
    });
    return filterJson;
  };

  sorter = (type, a, b) => {
    if (a[type] < b[type]) return -1;
    if (a[type] > b[type]) return 1;
  };

  showGraphs() {
    const Column = Table.Column;

    if (!(this.state.tabela_consulta_alunos.length > 0)) return null;

    // Get an array containing the field names
    // const fields = Object.keys(this.state.tabela_consulta_alunos[0]);

    return (
      <Row>
        <Col span={24}>
          <Card>
            <RowTitle text="Consulta de Alunos" />
            <Row>
              <Col style={{ width: "100%", minHeight: 1500 }}>
                <Table
                  dataSource={this.state.tabela_consulta_alunos}
                  scroll={{ x: 240 }}
                  size="small"
                  pagination={{ pageSize: 60 }}
                >
                  <Column
                    title="Ano/Série"
                    filters={this.filter("TX_ALUNO_ANO_SERIE")}
                    onFilter={(value, record) => {
                      if (record.TX_ALUNO_ANO_SERIE !== undefined)
                        return record.TX_ALUNO_ANO_SERIE.includes(value);
                    }}
                    // sorter={(a, b) => this.sorter("TX_ALUNO_ANO_SERIE", a, b)}
                    dataIndex="TX_ALUNO_ANO_SERIE"
                    key="TX_ALUNO_ANO_SERIE"
                  />
                  <Column
                    title="RA"
                    dataIndex="NU_ALUNO_ID"
                    key="NU_ALUNO_ID"
                  />
                  <Column
                    title="Nome"
                    // sorter={(a, b) => this.sorter("TX_ALUNO_NOME", a, b)}
                    dataIndex="TX_ALUNO_NOME"
                    key="TX_ALUNO_NOME"
                  />
                  <Column
                    title="Turma"
                    filters={this.filter("TX_ALUNO_TURMA")}
                    onFilter={(value, record) => {
                      if (record.TX_ALUNO_TURMA !== undefined)
                        return record.TX_ALUNO_TURMA.includes(value);
                    }}
                    // sorter={(a, b) => this.sorter("TX_ALUNO_TURMA", a, b)}
                    dataIndex="TX_ALUNO_TURMA"
                    key="TX_ALUNO_TURMA"
                  />
                  <Column
                    title="Escola"
                    filters={this.filter("TX_ESCOLA_NOME")}
                    onFilter={(value, record) => {
                      if (record.TX_ESCOLA_NOME !== undefined)
                        return record.TX_ESCOLA_NOME.includes(value);
                    }}
                    // sorter={(a, b) => this.sorter("TX_ESCOLA_NOME", a, b)}
                    dataIndex="TX_ESCOLA_NOME"
                    key="TX_ESCOLA_NOME"
                  />
                  <Column
                    title="Nota Geral"
                    // sorter={(a, b) => this.sorter("NOTA_GERAL", a, b)}
                    dataIndex={"NOTA_GERAL"}
                    render={(value, row) => value}
                    key={"NOTA_GERAL"}
                  />
                  <Column
                    title="Ranking Turma"
                    // sorter={(a, b) => this.sorter("NU_RANK_TURMA", a, b)}
                    dataIndex="NU_RANK_TURMA"
                    key="NU_RANK_TURMA"
                  />
                  {false ? (
                    <Column
                      title="Ranking Série"
                      //   sorter={(a, b) => this.sorter("NU_RANK_SERIE", a, b)}
                      dataIndex="NU_RANK_SERIE"
                      key="NU_RANK_SERIE"
                    />
                  ) : null}
                  {this.props.dataSelect.disc ? (
                    <Column
                      title={`Nota ${this.props.dataSelect.disc}`}
                      //   sorter={(a, b) => this.sorter("NOTA_MAT", a, b)}
                      dataIndex={"NOTA_MAT"}
                      key={"NOTA_MAT"}
                      render={(value, row) => value}
                    />
                  ) : null}
                </Table>
              </Col>
            </Row>
          </Card>
        </Col>
      </Row>
    );
  }

  showError = () => {
    return (
      <Row>
        <Col span={24}>
          <Card>
            {this.state.error ? (
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <i className="icon icon-error" style={{ fontSize: 32 }}></i>
                <p>
                  Nenhum resultado encontrado, por favor selecione novos dados.
                </p>
              </Row>
            ) : (
              <Row
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                }}
              >
                <i className="icon icon-alert" style={{ fontSize: 32 }}></i>
                <p>Por favor, selecione os dados a serem pesquisados.</p>
              </Row>
            )}
          </Card>
        </Col>
      </Row>
    );
  };
}

const mapStateToProps = ({ graph }) => {
  const { dataRoute, dataSelect, dataFilter, updateGraphs } = graph;
  return { dataRoute, dataSelect, dataFilter, updateGraphs };
};

export default connect(mapStateToProps, {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate,
})(consultaAlunos);
