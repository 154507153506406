import React from "react";
import {Route, Switch} from "react-router-dom";

import Components from "./components/index";
import CustomViews from "./customViews/index";
import Extensions from "./extensions/index";
import ExtraComponents from "./extraComponents/index";
import InBuiltApps from "./inBuiltApps/index";
import Main from "./main/index";
import Avaliacoes from "./avaliacoes";
import Static from "./static";
import Manager from "./manager/index";
import Documents from "./documents/index";

const App = ({match}) => (
  <div className="gx-main-content-wrapper">
    <Switch>
      <Route path={`${match.url}main`} component={Main}/>
      <Route path={`${match.url}avaliacoes`} component={Avaliacoes}/>
      <Route path={`${match.url}static`} component={Static}/>
      <Route path={`${match.url}manager`} component={Manager}/>
    </Switch>
  </div>
);

export default App;
