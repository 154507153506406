import {axios, path} from './requestConfig';

export const signIn = (user) => {
    return new Promise((resolve, reject) => {
        axios.post(path()+"/user/authenticate", user).then((result) =>{
            resolve(result);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const signInWithHash = ({loginToken}) => {
    if(loginToken){
        return new Promise((resolve, reject) => {
            axios.get(path()+"/user/authenticate/"+loginToken).then((result) =>{
                resolve(result);
            }).catch((err) => {
                reject(err);
            })
        })
    }
}

export const signUpNewUser = (user) => {
    const user_id = localStorage.getItem('user_id');

    if(typeof user_id !== 'undefined'){
        axios.defaults.headers.common['Authorization'] = "Bearer "+ user_id;
    }
    return new Promise((resolve, reject) => {
        axios.post(path()+"/user/register", user).then((result) =>{
            resolve(result);
        }).catch((err) => {
            reject(err);
        })
    })
}

export const tableToXls = (jsonTable, filename) => {
    return new Promise((resolve, reject) => {
        axios.post(path()+"/graph/xls", {
            jsonTable, filename
        },{
            responseType: 'blob'
        }).then((result) =>{
            resolve(result);
        }).catch((err) => {
            reject(err);
        })
    })
}