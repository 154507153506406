import React from "react";
import {Card, Col, Row, Table} from "antd";

import {connect} from "react-redux";

import {download} from 'util/utils';

import {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
} from "appRedux/actions/Graph";

import ChartInfo from "../components/chartInfo";
import RowTitle from "../components/rowTitle";
import PageHeader from "../components/pageHeader";

import {axios, path} from '../../../requestEvents/requestConfig';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting'
HC_exporting(Highcharts);

class matrizes extends React.Component {
 

  constructor(props){
    super(props);
    this.state = {
      tabela_temas_matriz_prova: '',
      grafico_itens_habilidades: '',
      tabela_composicao_prova: '',
      grafico_itens_complexidade: '',
      grafico_acertos_tema: '',
      grafico_acertos_item: '',
      grafico_acertos_habilidade: '',
      tabela_assuntos: '',
      tabela_melhores_habilidades: '',
      tabela_piores_habilidades: '',
      no_data: true,
      error: false
    }
  }

  exam = window.exam;

  componentWillMount() {
    this.props.graphChangeRoute('/matrizreferencia');
    this.props.graphSendUpdate(true);
  }

  componentDidUpdate () {
    if(this.props.updateGraphs){
      let dataSelect = this.props.dataSelect;
      let dataRoute = this.props.dataRoute;

      if (dataSelect.rede == null && localStorage.getItem('redes')) {
        if (JSON.parse(localStorage.getItem('redes').split(",")).length == 1) {
          dataSelect.rede = JSON.parse(localStorage.getItem('redes'))[0].rede;
        }
      }

      if (dataSelect.escola == null && localStorage.getItem('escolas')) {
        if (localStorage.getItem('escolas').split(",").length == 1) {
          dataSelect.escola = localStorage.getItem('escolas');
        }
      }
      
      axios.post(path()+"/graph/", {
        dataSelect,
        dataRoute
      }).then((result) => {
        if(result.data){
          if(!result.data.error){
            let tabela_temas_matriz_prova = result.data.tabela_temas_matriz_prova;
            let grafico_itens_habilidades = result.data.grafico_itens_habilidades;
            let tabela_composicao_prova = result.data.tabela_composicao_prova;
            for (let i = 0; i < tabela_composicao_prova.length; i++) {
              tabela_composicao_prova[i].GABARITO = tabela_composicao_prova[i].TX_GABARITO == undefined ? tabela_composicao_prova[i].CH_ITEM_GABARITO : tabela_composicao_prova[i].TX_GABARITO;
              tabela_composicao_prova[i].POSICAO = tabela_composicao_prova[i].TX_ITEM_NUMERACAO == undefined ? tabela_composicao_prova[i].NU_ITEM_POSICAO_ANO_SERIE : tabela_composicao_prova[i].TX_ITEM_NUMERACAO;
            }
            let grafico_itens_complexidade = result.data.grafico_itens_complexidade;
            let grafico_acertos_tema = result.data.grafico_acertos_tema;
            let grafico_acertos_item = result.data.grafico_acertos_item;
            let tabela_assuntos = result.data.tabela_assuntos;
            let grafico_acertos_habilidade = result.data.grafico_acertos_habilidade;
            let tabela_melhores_habilidades = result.data.tabela_melhores_habilidades;
            let tabela_piores_habilidades = result.data.tabela_piores_habilidades;

            this.setState({
              tabela_temas_matriz_prova,
              grafico_itens_habilidades,
              tabela_composicao_prova,
              grafico_itens_complexidade,
              grafico_acertos_tema,
              grafico_acertos_item,
              grafico_acertos_habilidade,
              tabela_assuntos,
              tabela_melhores_habilidades,
              tabela_piores_habilidades,
              no_data: false,
              error: false
            })
          }else{
            this.setState({
              no_data: true,
              error: true
            })
          }
        }else{
          this.setState({
            no_data: true,
            error: false
          })
        }
      }).catch((err) => {
          this.setState({
            no_data: true,
            error: true
          })
      })
      this.props.graphSendUpdate(false);
    }
  }

  showError = () =>{
    return (
      <Row>
        <Col span={24}>
          <Card>
            {
              this.state.error ?
                <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <i className="icon icon-error" style={{fontSize: 32}}></i>
                  <p>Nenhum resultado encontrado, por favor selecione novos dados.</p>
                </Row>
              :
              <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <i className="icon icon-alert" style={{fontSize: 32}}></i>
                <p>Por favor, selecione os dados a serem pesquisados.</p>
              </Row>
            }
          </Card>
        </Col>
      </Row>
    )
  }

  sorter = (type, a, b) => {
      if(a[type]<b[type]) return -1
      if(a[type]>b[type]) return 1
  }

  showGraphs() {
    const Column = Table.Column;

    return (
      <Row>

        {
          this.state.tabela_assuntos ?
          <Col span={24}>
            <Card >
              <RowTitle text={ exam.tables.subject.title } />
              <Row>
                <Col style={{width: '100%'}}>
                  <Table dataSource={this.state.tabela_assuntos} 
                        pagination={{ pageSize: 12 }}>
                    <Column
                        title="Código"
                        dataIndex="TX_HABILIDADE_SHORTNAME"
                        key="TX_HABILIDADE_SHORTNAME"
                        sorter={(a, b) => this.sorter("TX_HABILIDADE_SHORTNAME", a, b)}
                    />
                    <Column
                        title={ exam.tables.subject.fieldName }
                        dataIndex="TX_HABILIDADE_LONGNAME"
                        key="TX_HABILIDADE_LONGNAME"
                        sorter={(a, b) => this.sorter("TX_HABILIDADE_LONGNAME", a, b)}
                    />
                    <Column
                        title="Capítulo"
                        dataIndex="TX_CAPITULO_LONGNAME"
                        key="TX_CAPITULO_LONGNAME"
                        sorter={(a, b) => this.sorter("TX_CAPITULO_LONGNAME", a, b)}
                    />
                    <Column
                        title="Tópico"
                        dataIndex="TX_TOPICO_LONGNAME"
                        key="TX_TOPICO_LONGNAME"
                        sorter={(a, b) => this.sorter("TX_TOPICO_LONGNAME", a, b)}
                    />
                  </Table>
                </Col>
                <ChartInfo text={ exam.tables.subject.chartInfo } />
              </Row>
            </Card>
          </Col> :
          null
        }


        <Col span={24}>
          <Card >
            <RowTitle text="Tabela de questões" />
            <Row>
              <Col style={{width: '100%'}}>
                <Table dataSource={this.state.tabela_composicao_prova} 
                      pagination={{ pageSize: 12 }}>
                  <Column
                    title="Posição"
                    dataIndex="POSICAO"
                    key="POSICAO"
                  />
                  <Column
                      title={ exam.tables.questions.fieldName }
                      dataIndex="TX_HABILIDADE_SHORTNAME"
                      key="TX_HABILIDADE_SHORTNAME"
                  />
                  <Column
                      title="Complexidade"
                      dataIndex="TX_DIFICULDADE_TCT"
                      key="TX_DIFICULDADE_TCT"
                  />
                  <Column
                      title="Índice de Acerto"
                      dataIndex="NU_DIFICULDADE_TCT"
                      key="NU_DIFICULDADE_TCT"
                  />
                  <Column
                      title="Gabarito"
                      dataIndex="GABARITO"
                      key="GABARITO"
                  />
                  <Column
                      title="Resposta A"
                      dataIndex="NU_PORC_A"
                      key="NU_PORC_A"
                  />
                  <Column
                      title="Resposta B"
                      dataIndex="NU_PORC_B"
                      key="NU_PORC_B"
                  />
                  <Column
                      title="Resposta C"
                      dataIndex="NU_PORC_C"
                      key="NU_PORC_C"
                  />
                  <Column
                      title="Resposta D"
                      dataIndex="NU_PORC_D"
                      key="NU_PORC_D"
                  />
                  <Column
                      title="Resposta E"
                      dataIndex="NU_PORC_E"
                      key="NU_PORC_E"
                  />
                  <Column
                      title="Sem Resposta"
                      dataIndex="NU_PORC_X"
                      key="NU_PORC_X"
                  />
                </Table>
                <div
                  onClick={() => {
                          return download(this.state.tabela_composicao_prova, "tabela_de_itens_avaliativos")}}
                  className="ant-btn ant-btn-primary invisible">
                  Download
                </div>
              </Col>
              <ChartInfo text="Essa tabela apresenta as questões que compõem o caderno de prova, relacionando a habilidade que avaliam, a complexidade pedagógica calculada, o índice de acertos, o gabarito e a distribuição de alternativas assinaladas pelos alunos." />
            </Row>

          </Card>
        </Col>

        <Col span={24}>
          <Card >
            <RowTitle text="Composição da prova por complexidade pedagógica" />
            <Row>
              <Col style={{width: '100%'}}>
                <HighchartsReact
                  style={{width: '100%'}}
                  highcharts={Highcharts}
                  options={this.state.grafico_itens_complexidade}
                />
              </Col>
              <ChartInfo text="Esse gráfico apresenta a distribuição de graus de complexidade das questões no caderno de prova. O eixo horizontal apresenta cada grau de complexidade e o eixo vertical apresenta o número de questões relacionadas à categoria. A escala de níveis de complexidade pode ser acessada pelo menu de <a href='./downloads/'>Downloads</a>." />
            </Row>
          </Card>
        </Col>

        {
          this.state.grafico_acertos_tema ?
        <Col span={24}>
          <Card >
            <RowTitle text="Índice de acerto por tema da Matriz de Referência" />
            <Row>
            <Col style={{width: '100%'}}>
                <HighchartsReact
                  style={{width: '100%'}}
                  highcharts={Highcharts}
                  options={this.state.grafico_acertos_tema}
                />
              </Col>
              <ChartInfo text="Esse gráfico apresenta o índice de acerto médio (eixo vertical),
                              em percentual, para cada tema da matriz de referência
                              (eixo horizontal)." />
            </Row>
          </Card>
        </Col> :
          null
        }

        <Col span={24}>
          <Card >
            <RowTitle text={ exam.tables.rightAnswersIndex.title } />
            <Row>
              <Col style={{width: '100%'}}>
                <HighchartsReact
                  style={{width: '100%'}}
                  highcharts={Highcharts}
                  options={this.state.grafico_acertos_habilidade}
                />
              </Col>
              <ChartInfo text={ exam.tables.rightAnswersIndex.chartInfo } />
            </Row>
          </Card>
        </Col>

        <Col span={24}>
          <Card >
            <RowTitle text="Destaques da Matriz de Referência" />
            <Row style={{display: 'flex', justifyContent: 'space-around'}}>
              <Col className="doubleTableColumn">
                <p className="title green">{ exam.tables.highlights.bestPerformanceTitle }</p>
                <Table  dataSource={this.state.tabela_melhores_habilidades} 
                        pagination={{ pageSize: 12 }}>
                  <Column
                    title="Código"
                    dataIndex="TX_HABILIDADE_SHORTNAME"
                    key="TX_HABILIDADE_SHORTNAME"
                  />
                  <Column
                      title="Descrição"
                      dataIndex="TX_HABILIDADE_LONGNAME"
                      key="TX_HABILIDADE_LONGNAME"
                  />
                  <Column
                      title="Índice de Acerto"
                      dataIndex="NU_DIFICULDADE_TCT"
                      key="NU_DIFICULDADE_TCT"
                  />
                </Table>
                <div
                  onClick={() => {
                          return download(this.state.tabela_melhores_habilidades, "melhores_habilidades")}}
                  className="ant-btn ant-btn-primary invisible">
                  Download
                </div>
              </Col>

              <Col className="doubleTableColumn">
                <p className="title red">{ exam.tables.highlights.worstPerformanceTitle }</p>
                <Table dataSource={this.state.tabela_piores_habilidades} 
                      pagination={{ pageSize: 12 }}>
                  <Column
                    title="Código"
                    dataIndex="TX_HABILIDADE_SHORTNAME"
                    key="TX_HABILIDADE_SHORTNAME"
                  />
                  <Column
                      title="Descrição"
                      dataIndex="TX_HABILIDADE_LONGNAME"
                      key="TX_HABILIDADE_LONGNAME"
                  />
                  <Column
                      title="Índice de Acerto"
                      dataIndex="NU_DIFICULDADE_TCT"
                      key="NU_DIFICULDADE_TCT"
                  />
                </Table>
                <div
                  onClick={() => {
                          return download(this.state.tabela_piores_habilidades, "piores_habilidades")}}
                  className="ant-btn ant-btn-primary invisible">
                  Download
                </div>
              </Col>
              <ChartInfo text={ exam.tables.highlights.chartInfo } />
            </Row>
          </Card>
        </Col>

      </Row>
    );
  }

  render() {
    return(
      <div>
        <PageHeader title="Matriz de Referência"
                    text="Nessa página, indicadores são elaborados com base na composição dos cadernos de prova e nos componentes da matriz&nbsp;de referência"
                    icon="icon-feedback" />
      {
        this.state.no_data ? 
          this.showError() :
          this.showGraphs()
      }
      </div>
    )
  }
};

const mapStateToProps = ({ graph }) => {
  const {dataRoute, dataSelect, dataFilter, updateGraphs} = graph;
  return {dataRoute, dataSelect, dataFilter, updateGraphs};
};

export default connect(mapStateToProps, {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
})(matrizes)