import jwt from 'jsonwebtoken';

export const getUserType = () => {
    const userTk = localStorage.getItem('user_id');
    if(userTk != null){
        try{
            const decoded = jwt.decode(userTk).type;
            return decoded;
        }catch(err){
            console.error(err);
            return '';
        }
    }else {
        return '';
    }
}