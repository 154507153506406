import React from "react";
import {
  Button,
  Checkbox,
  Form,
  Icon,
  Input,
  Select,
  message,
  Switch,
  Transfer
} from "antd";
import { Link } from "react-router-dom";
import CircularProgress from "components/CircularProgress/index";
import {
  hideMessage,
  showAuthLoader,
  userSignUp
} from "appRedux/actions/Auth";

import { connect } from "react-redux";

const FormItem = Form.Item;
const Option = Select.Option;

class CreateUserForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      passwordType: "password",
      escolasDisponiveis: [],
      escolasEscolhidas: [],
      redesEscolhidas: []
    }
  }

  componentDidUpdate() {
    if (this.props.showMessage || this.props.showSuccessMessage) {
      setTimeout(() => {
        this.props.hideMessage();
        this.props.history.push('/manager/userList');
      }, 100);
    }
  }

  togglePassword = () => {
    let passwordType = this.state.passwordType === 'password' ?
      "text" :
      "password"
    this.setState({ passwordType })
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignUp(values);
      }
    });
  };

  handleChangeRedes = (redesEscolhidas, direction, moveKeys) => {
    this.setState({ redesEscolhidas });
    this.fillEscolasDisponiveis(redesEscolhidas)
  }

  handleChangeEscolas = (escolasEscolhidas, direction, moveKeys) => {
    this.setState({ escolasEscolhidas });
  }

  fillEscolasDisponiveis = (redes) => {
    const escolasDisponiveis = redes.reduce((escolas, rede) => {
      if(rede.escolas !== null){
          rede.escolas.map((e, index) => {
            escolas.push(e);
          })
        }
        return escolas
    }, [])
    this.setState({escolasDisponiveis})
  }

  render() {
    const { getFieldDecorator } = this.props.form;
    const { showMessage, showSuccessMessage, loader, alertMessage } = this.props;
    return (
      <div className="gx-app-login-wrap">
        <Link className="fake-button left" to={`/manager/userList`}>
          <Button type="primary">
            <Icon type="left" />
            Voltar
                </Button>
        </Link>
        <div className="gx-app-login-container">
          <div className="gx-app-login-main-content">
            <div className="gx-app-login-content" style={{ width: "100%", padding: 35 }}>
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <h1 style={
                  {
                    fontSize: "2em",
                    textAlign: "center"
                  }
                }>
                  Criar novo Usuário
                    </h1>
                <FormItem>
                  {getFieldDecorator('username', {
                    rules: [{ required: true }],
                  })(
                    <Input placeholder="Username" />
                  )}
                </FormItem>
                <FormItem >
                  {getFieldDecorator('email', {
                    rules: [{ required: true }],
                  })(
                    <Input placeholder="Email" />
                  )}
                </FormItem>
                <FormItem >
                  {getFieldDecorator('password', {
                    rules: [{ required: true }],
                  })(
                    <Input type={this.state.passwordType} placeholder="Senha" />
                  )}
                </FormItem>
                <FormItem
                  className="form-inline-row"
                  label="Exibir senha: "
                >
                  <Switch onChange={this.togglePassword} />
                </FormItem>
                <FormItem >
                  {getFieldDecorator('userType', {
                    rules: [{ required: true }],
                  })(
                    <Select placeholder="Tipo de usuário">
                      <Option value="REDE">REDE</Option>
                      <Option value="GRUPO">GRUPO</Option>
                      <Option value="ESCOLA">ESCOLA</Option>
                    </Select>
                  )}
                </FormItem>

                
                <FormItem >
                  {getFieldDecorator('redes', {
                      rules: [{ required: true }],
                    })(
                    <Transfer 
                      dataSource={redes}
                      showSearch
                      titles={['Redes', 'Redes Escolhidas']}
                      listStyle={{
                        width: 250,
                        height: 200,
                      }}
                      operations={['adicionar', 'remover']}
                      targetKeys={this.state.redesEscolhidas}
                      onChange={this.handleChangeRedes}
                      render={item => item.nome}
                    >
                    </Transfer>
                  )}
                </FormItem>

                {
                  this.state.escolasDisponiveis.length > 0 ?
                    <FormItem>
                      {getFieldDecorator('escolas', {
                        rules: [{ required: true }],
                      })(
                      <Transfer 
                        dataSource={this.state.escolasDisponiveis}
                        showSearch
                        titles={['Escolas', 'Escolas Escolhidas']}
                        listStyle={{
                          width: 250,
                          height: 200,
                        }}
                        operations={['adicionar', 'remover']}
                        targetKeys={this.state.escolasEscolhidas}
                        onChange={this.handleChangeEscolas}
                        render={item => item.nome}
                      >
                      </Transfer>
                      )}
                    </FormItem> :
                    null
                }

                <FormItem>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <span>Criar usuário</span>
                  </Button>
                </FormItem>
              </Form>
            </div>
            {loader ?
              <div className="gx-loader-view">
                <CircularProgress />
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
            {showSuccessMessage ?
              message.success(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedManagerForm = Form.create()(CreateUserForm);

const mapStateToProps = ({ auth }) => {
  const { loader, alertMessage, showMessage, showSuccessMessage, authUser } = auth;
  return { loader, alertMessage, showMessage, showSuccessMessage, authUser }
};

export default connect(mapStateToProps, {
  userSignUp,
  hideMessage,
  showAuthLoader
})(WrappedManagerForm);