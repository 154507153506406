import React from "react";
import { Button, Table, Divider, Tag, Modal, Icon, Input} from 'antd';
import {Link} from "react-router-dom";
import {axios, path} from '../../requestEvents/requestConfig';

const Column = Table.Column;
const Search = Input.Search;

export default class UserList extends React.Component {
    constructor(props){
        super(props);
        this.state= {
            users: []
        }
    }
  
    componentDidMount(){
        this.getUserList();
    }
  
    getUserList = () => {
        axios.get(path()+"/user/")
          .then((result) => {
              this.updateUsersList(result.data);
          }).catch((err) => {
              console.error(err);
          })
    }

    searchUser = (value) => {
        if(value.trim() === "")
            this.getUserList()
        else{
            axios.post(path()+"/user/search", {query: value})
                .then((result) => {
                    this.updateUsersList(result.data);
                }).catch((err) => {
                    console.error(err);
                })
        }
    }
    callDeletePopup = (id, username) => {
      Modal.confirm({
          title: "Deletar usuário",
          content: "Você tem certeza que deseja deletar o usuário: "+username,
          okText: "deletar",
          cancelText: "cancelar",
          onOk: () => {this.handleDelete(id)},
      })
    }
  
    handleDelete = (id) => {
      axios.delete(path()+"/user/"+id)
          .then((result) => {
              this.updateUsersList(result.data);
          })
          .catch((err) => {
              console.log(err);
          })
    }
  
    updateUsersList = (userList) => {
      let newUsers = userList.map((user, index)=>{
          if(user.userType !== 'SUPER_ADMIN'){
              return {
                  ...user, 
                  editar: (
                    <a key={"edit"+index} onClick={() => this.props.history.push('/manager/editUser/'+user.id)}
                        href="javascript:void(0)">
                        editar
                    </a>
                  ),
                  deletar: (
                    <a key={"delete"+index} onClick={() => this.callDeletePopup(user.id, user.username)}
                        href="javascript:void(0)">
                        deletar
                    </a>
                  )
              }
          }else{
              return user;
          }
      });
      this.setState({users: newUsers});
    }

    render(){
        return (
            <div className="custom-box-wrapper">
                <Link className="fake-button right" to={`/manager/createUser`}>
                    <Button type="primary">
                        Criar Usuário
                        <Icon type="right" />
                    </Button>
                </Link>
                <Search
                    placeholder="Busque por username, email ou tipo"
                    onSearch={value => this.searchUser(value)}
                    style={{width: 350, margin: '0 auto'}}
                    enterButton
                />
                <div className="custom-box" style={{width: '100%'}}>
                    <Table dataSource={this.state.users} 
                        pagination={{ pageSize: 12 }}
                        scroll={{x: 100}}
                        defaultSortOrder="ascend">
                        <Column
                            title="Username"
                            dataIndex="username"
                            key="username"
                        />
                        <Column
                            title="Email"
                            dataIndex="email"
                            key="email"
                        />
                        <Column
                            title="Tipo"
                            dataIndex="userType"
                            key="userType"
                        />
                        <Column
                            title="Data de criação"
                            dataIndex="createdDate"
                            key="createdDate"
                        />
                        <Column
                            title="Login Token"
                            dataIndex="loginToken"
                            key="loginToken"
                        />
                        <Column
                            title=""
                            dataIndex="editar"
                            key="editar"
                        />
                        <Column
                            title=""
                            dataIndex="deletar"
                            key="deletar"
                        />
                    </Table>
                </div>
            </div>
        );
    }
}
