import React from "react";
import { Select, Button } from 'antd';
import {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
} from "appRedux/actions/Graph";

import { getUserRedes, getUserEscolas } from 'util/storage';


import { connect } from "react-redux";

class ConsultaAlunosRedeEscolaDataSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            ava: this.props.dataSelect.ava,
            disc: undefined,
            rede: undefined,
            escola: undefined
        }
    }

    sendData = () => {
        this.props.graphChangeFilter({
            ava: this.state.ava,
            disc: this.state.disc,
            as: '',
            rede: this.props.dataSelect.rede == undefined ? null : this.props.dataSelect.rede,
            escola: this.props.dataSelect.escola == undefined ? null : this.props.dataSelect.escola
        });
        this.props.graphSendUpdate(true);
    }

    selectData = (select, value) => {
        switch (select) {
            case "ano": {
                this.setState({
                    ava: value
                })
                break;
            }
            case "disciplina": {
                this.setState({
                    disc: value
                })
                break;
            }
            case "rede": {
                this.setState({
                    rede: value
                })
                break;
            }
            case "escola": {
                this.setState({
                    escola: value
                })
                break;
            }
        }
    }

    renderEscolasByRede = (redeSelecionada) => {
        const redeObj = getUserRedes().filter(rede => rede.rede === redeSelecionada)
        const userEscolas = getUserEscolas();
        const escolasDisponiveis = redeObj.reduce((escolas, rede) => {
            rede.escolas.forEach((escola) => {
                if (userEscolas.indexOf(escola.key) >= 0)
                    escolas.push(escola);
            })
            return escolas
        }, [])
        const Option = Select.Option;
        return (

            <Select
                showSearch
                style={{ width: 125, marginRight: 20 }}
                value={this.state.escola}
                onChange={(value) => { this.selectData("escola", value) }}
                placeholder="Escola"
            >
                {
                    escolasDisponiveis.map((escola, index) => {
                        return (
                            <Option value={escola.key}>
                                {escola.key}
                            </Option>
                        )
                    })
                }
            </Select>
        )
    }

    render() {
        const Option = Select.Option;

        return (
            <div style={{ display: 'flex' }}>
                <Select
                    showSearch
                    onChange={(value) => { this.selectData("ano", value) }}
                    style={{ width: 125, marginRight: 20, display: "none" }}
                    placeholder="Ano"
                >
                    <Option value="DD001">2019</Option>
                </Select>
                <Select
                    showSearch
                    // style={{ width: 200, marginBottom: 20, marginTop: 20 }}
                    value={this.state.rede}
                    style={{ width: 125, marginRight: 20, display: "none" }}
                    onChange={(value) => { this.selectData("rede", value) }}
                    placeholder="Rede de escolas"
                >
                    {
                        getUserRedes().map((rede, index) => {
                            const isDuplicate = getUserRedes().slice(0, index).some((r) => r.rede === rede.rede);
                            if (isDuplicate) {
                                return null;
                            }
                            return (
                                <Option key={rede.rede} value={rede.rede}>
                                    {rede.rede.charAt(0).toUpperCase() + rede.rede.slice(1)}
                                </Option>
                            );
                        })

                    }
                </Select>
                {
                    this.state.rede ?
                        this.renderEscolasByRede(this.state.rede) :
                        null
                }
                <Select
                    showSearch
                    onChange={(value) => { this.selectData("disciplina", value) }}
                    style={{ width: 125, marginRight: 20 }}
                    placeholder="Disciplina"
                >
                    <Option value="">(Geral)</Option>
                    {
                        window.disciplines.map((item, index) => {
                            const isDuplicate = disciplines.slice(0, index).some((t) => t.code === item.code);
                            if (isDuplicate) {
                                return null;
                            }
                            return (
                                <Option key={item.code} value={item.code}>
                                    {item.name}
                                </Option>
                            );
                        })
                    }
                </Select>

                <Button type="primary" onClick={this.sendData}>
                    Selecionar dados
                </Button>
            </div>
        );
    }
}

const mapStateToProps = ({ graph }) => {
    const { dataRoute, dataSelect, dataFilter, updateGraphs } = graph;
    return { dataRoute, dataSelect, dataFilter, updateGraphs };
};

export default connect(mapStateToProps, {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
})(ConsultaAlunosRedeEscolaDataSelect)
