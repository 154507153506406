import React from "react";
import {Card, Col, Row, Table} from "antd";

import ChartInfo from "../components/chartInfo";
import RowTitle from "../components/rowTitle";
import PageHeader from "../components/pageHeader";

import {connect} from "react-redux";

import {download} from 'util/utils';
import {getUserType} from "util/decoders"


import {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
} from "appRedux/actions/Graph";

import {axios, path} from '../../../requestEvents/requestConfig';

import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting'
HC_exporting(Highcharts);

class mediasFaixas extends React.Component {
  
  constructor(props){
    super(props);
    this.state = {
      grafico_histograma_medias: '',
      grafico_niveis_rede: '',
      grafico_medias_rede: '',
      tabela_segmentacao_proficiencias: '',
      tabela_compara_turmas: '',
      no_data: true,
      error: false
    }
  }

  componentDidMount() {
    this.props.graphChangeRoute('/faixasemedias');
    this.props.graphSendUpdate(true);
  }

  componentDidUpdate () {
    if(this.props.updateGraphs){
      let dataSelect = this.props.dataSelect;
      let dataRoute = this.props.dataRoute;

      if (dataSelect.rede == null && localStorage.getItem('redes')) {
        if (JSON.parse(localStorage.getItem('redes').split(",")).length == 1) {
          dataSelect.rede = JSON.parse(localStorage.getItem('redes'))[0].rede;
        }
      }

      if (dataSelect.escola == null && localStorage.getItem('escolas')) {
        if (localStorage.getItem('escolas').split(",").length == 1) {
          dataSelect.escola = localStorage.getItem('escolas');
        }
      }

      axios.post(path()+"/graph/", {
        dataSelect,
        dataRoute
      }).then((result) => {
        if(result.data){
          if(!result.data.error){
            let grafico_histograma_medias = result.data.grafico_histograma_medias;
            let grafico_niveis_rede = result.data.grafico_niveis_rede;
            let grafico_medias_rede = result.data.grafico_medias_rede;
            let tabela_segmentacao_proficiencias = result.data.tabela_segmentacao_proficiencias;
            let tabela_compara_turmas = result.data.tabela_compara_turmas;

            this.setState({
              grafico_histograma_medias,
              grafico_niveis_rede,
              grafico_medias_rede,
              tabela_segmentacao_proficiencias,
              tabela_compara_turmas,
              no_data: false,
              error: false
            })
          }else{
            this.setState({
              no_data: true,
              error: true
            })
          }
        }else{
          this.setState({
            no_data: true,
            error: false
          })
        }
      }).catch((err) => {
          this.setState({
            no_data: true,
            error: true
          })
      })
      this.props.graphSendUpdate(false);
    }
  }

  showError = () =>{
    return (
      <Row>
        <Col span={24}>
          <Card>
            {
              this.state.error ?
                <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                  <i className="icon icon-error" style={{fontSize: 32}}></i>
                  <p>Nenhum resultado encontrado, por favor selecione novos dados.</p>
                </Row>
              :
              <Row style={{display: 'flex', flexDirection: 'column', alignItems: 'center'}}>
                <i className="icon icon-alert" style={{fontSize: 32}}></i>
                <p>Por favor, selecione os dados a serem pesquisados.</p>
              </Row>
            }
          </Card>
        </Col>
      </Row>
    )
  }

  showGraphs = () => {
    const Column = Table.Column;
    const usertype = getUserType();
    const showRestrictInfo = usertype === "SUPER_ADMIN" || usertype === "GRUPO";

    return (
      <Row>
        <Col span={24}>
          <Card >
            <RowTitle text="Histograma de Notas" />
            <Row>
              <Col style={{width: '100%'}}>
                  <HighchartsReact
                    style={{width: '100%'}}
                    highcharts={Highcharts}
                    options={this.state.grafico_histograma_medias}
                  />
                </Col>
                <ChartInfo text="Nesse gráfico, o eixo horizontal representa as pontuações dos alunos, agrupadas em intervalos de 1 ponto, e o eixo vertical mostra o número de alunos associado a cada intervalo. A cor de cada barra representa o mapeamento de cada intervalo para o nível de proficiência correspondente, que pode ser acessado pelo menu de <a href='./downloads/'>Downloads</a>" />
            </Row>
          </Card>
        </Col>

        {

        showRestrictInfo ?
        <Col span={24}>
          <Card >
            <RowTitle text="Níveis de Proficiências por rede de escolas" />
            <Row>
              <Col style={{width: '100%'}}>
                <HighchartsReact
                  style={{width: '100%'}}
                  highcharts={Highcharts}
                  options={this.state.grafico_medias_rede}
                />
              </Col>
              <Col style={{width: '100%'}}>
                <HighchartsReact
                  style={{width: '100%'}}
                  highcharts={Highcharts}
                  options={this.state.grafico_niveis_rede}
                />
              </Col>
              <ChartInfo text="Nesses gráficos, é possível visualizar a distribuição de
                              níveis de proficiência e proficiências médias estimadas ao
                              longo de todos os grupos (redes de escolas) avaliados." />
            </Row>
          </Card>
        </Col> :
        null
        }

        <Col span={24}>
          <Card >
            <RowTitle text="Desempenho médio por turma" />
            <Row>
              <Col style={{width: '100%'}}>
                <Table dataSource={this.state.tabela_compara_turmas} 
                      pagination={{ pageSize: 12 }}>
                  <Column
                      title="Escola"
                      dataIndex="TX_ESCOLA_NOME"
                      key="TX_ESCOLA_NOME"
                  />
                  <Column
                      title="Ano Escolar"
                      dataIndex="TX_ALUNO_ANO_SERIE"
                      key="TX_ALUNO_ANO_SERIE"
                  />
                  <Column
                      title="Turma"
                      dataIndex="TX_ALUNO_TURMA"
                      key="TX_ALUNO_TURMA"
                  />
                  <Column
                      title="Média"
                      dataIndex="NU_MEDIA"
                      key="NU_MEDIA"
                  />
                </Table>
                <div
                  onClick={() => {
                          return download(this.state.tabela_compara_turmas, "Segmentação_em_níveis_detalhados_de_proficiência")}}
                  className="ant-btn ant-btn-primary invisible">
                  Download
                </div>
              </Col>
              <ChartInfo text="Essa tabela permite que a comparação de desempenho de cada turma dos alunos avaliados. Com esse dado, é possível inferir a uniformidade entre as turmas, ou identificar desvios pontuais." />
            </Row>
          </Card>
        </Col>

        {
          this.state.tabela_segmentacao_proficiencias ?
        <Col span={24}>
          <Card >
            <RowTitle text="Segmentação em níveis detalhados de proficiência" />
            <Row>
              <Col style={{width: '100%'}}>
                <Table dataSource={this.state.tabela_segmentacao_proficiencias} 
                      pagination={{ pageSize: 12 }}>
                  <Column
                      title="Nível detalhado de proficiência"
                      dataIndex="TX_NIVEL_DETALHADO"
                      key="TX_NIVEL_DETALHADO"
                  />
                  <Column
                      title="Alunos"
                      dataIndex="NU_TOTAL_ALUNOS_ACIMA"
                      key="NU_TOTAL_ALUNOS_ACIMA"
                  />
                  <Column
                      title="Porcentagem de alunos"
                      dataIndex="NU_PERCENTUAL_ALUNOS_ACIMA"
                      key="NU_PERCENTUAL_ALUNOS_ACIMA"
                  />
                </Table>
                <div
                  onClick={() => {
                      return download(this.state.tabela_segmentacao_proficiencias, "Segmentação_em_níveis_detalhados_de_proficiência")}}
                  className="ant-btn ant-btn-primary invisible">
                  Download
                </div>
              </Col>
              <ChartInfo text="Esta tabela permite visualizar a quantidade e porcentagem de alunos que supera
                              cada nível detalhado (segmentado) de proficiência. Para mais informações
                              sobre os níveis detalhados de proficiência, visite<a href='http://saresp.vunesp.com.br/' target='_blank'>
                              http://saresp.vunesp.com.br/</a>" />
            </Row>
          </Card>
        </Col>  :
          null
        }
      </Row>
    );
  }
  
  render() {
    return (
      <div>
        <PageHeader title="Médias e Faixas"
                    text="Uma visão detalhada, para cada ano/série, de como se distribuem os alunos de acordo com sua rede de escolas, proficiência estimada e nível de proficiência."
                    icon="icon-data-display" />
        {
          this.state.no_data ? 
            this.showError() :
            this.showGraphs()
        }
      </div>
    )
  }
};

const mapStateToProps = ({ graph }) => {
  const {dataRoute, dataSelect, dataFilter, updateGraphs} = graph;
  return {dataRoute, dataSelect, dataFilter, updateGraphs};
};

export default connect(mapStateToProps, {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
})(mediasFaixas)
