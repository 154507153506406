import requestAxios from 'axios';


export const path = () =>{
    const user_id = localStorage.getItem('user_id');
    
    if(typeof user_id !== undefined){
        requestAxios.defaults.headers.common['Authorization'] = "Bearer "+ user_id;
    }

    return window._env_.REACT_APP_BACKEND_URL || `${window.location.protocol}//${window.location.hostname}:5000`;
}

export const axios =  requestAxios;
