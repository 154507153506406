import React from "react";
import ReactHtmlParser from 'react-html-parser';
import {Card, Col, Row, Table, Icon} from "antd";


export default class chartInfo extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            text: props.text
        }
    }  

    render() {

        return (
            <Row className="chart-info-row">
              <Icon type="info-circle" className="chart-info-row_icon" />
              <Col className="chart-info-row_col">
                <p>
                  {ReactHtmlParser(this.state.text)}
                </p>
              </Col>
            </Row>
        ) 
    }
}