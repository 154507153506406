// Customizer const
export const TOGGLE_COLLAPSED_NAV = 'TOGGLE_COLLAPSE_MENU';
export const WINDOW_WIDTH = 'WINDOW-WIDTH';
export const SWITCH_LANGUAGE = 'SWITCH-LANGUAGE';

//Contact Module const
export const SHOW_MESSAGE = 'SHOW_MESSAGE';
export const SHOW_SUCCESS_MESSAGE = 'SHOW_SUCCESS_MESSAGE';
export const HIDE_MESSAGE = 'HIDE_MESSAGE';
export const ON_SHOW_LOADER = 'ON_SHOW_LOADER';
export const ON_HIDE_LOADER = 'ON_HIDE_LOADER';

//Auth const
export const SIGNUP_USER = 'SIGNUP_USER';
export const SIGNUP_USER_SUCCESS = 'SIGNUP_USER_SUCCESS';
export const SIGNIN_USER = 'SIGNIN_USER';
export const SIGNIN_USER_HASH = 'SIGNIN_USER_HASH';
export const SIGNIN_USER_SUCCESS = 'SIGNIN_USER_SUCCESS';
export const SIGNOUT_USER = 'SIGNOUT_USER';
export const SIGNOUT_USER_SUCCESS = 'SIGNOUT_USER_SUCCESS';
export const INIT_URL = 'INIT_URL';

//GRAPH CONST
export const GRAPH_ROUTE = 'GRAPH_ROUTE';
export const GRAPH_SELECT = 'GRAPH_SELECT';
export const GRAPH_SELECT_ANO = 'GRAPH_SELECT_ANO';
export const GRAPH_SELECT_DISCIPLINA = 'GRAPH_SELECT_DISCIPLINA';
export const GRAPH_SELECT_SERIE = 'GRAPH_SELECT_SERIE';
export const GRAPH_FILTER = 'GRAPH_FILTER';
export const GRAPH_FILTER_REDE = 'GRAPH_FILTER_REDE';
export const GRAPH_FILTER_ESCOLA = 'GRAPH_FILTER_ESCOLA';
export const GRAPH_UPDATE = 'GRAPH_UPDATE';
export const GRAPH_CLEAR = 'GRAPH_CLEAR';