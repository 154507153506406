import React from "react";
import { Card, Col, Row, Table } from "antd";
import { connect } from "react-redux";
import { download } from 'util/utils';
import ChartInfo from "../components/chartInfo";
import RowTitle from "../components/rowTitle";
import PageHeader from "../components/pageHeader";
import {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
} from "appRedux/actions/Graph";
import { axios, path } from '../../../requestEvents/requestConfig';
import Highcharts from 'highcharts'
import HighchartsReact from 'highcharts-react-official'
import HC_exporting from 'highcharts/modules/exporting'

HC_exporting(Highcharts);

class visaoGeral extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      grafico_visao_geral_agrupado: '',
      grafico_visao_geral_empilhado: '',
      tabela_visao_geral: '',
      no_data: true,
      error: false
    }
  }

  componentDidMount() {
    this.props.graphChangeRoute('/visaogeral');
    this.props.graphSendUpdate(true);
  }

  componentDidUpdate() {
    if (this.props.updateGraphs) {
      let dataSelect = this.props.dataSelect;
      let dataRoute = this.props.dataRoute;
      if (dataSelect.rede == null && localStorage.getItem('redes')) {
        if (JSON.parse(localStorage.getItem('redes').split(",")).length == 1) {
          dataSelect.rede = JSON.parse(localStorage.getItem('redes'))[0].rede;
        }
      }
      if (dataSelect.escola == null && localStorage.getItem('escolas')) {
        if (localStorage.getItem('escolas').split(",").length == 1) {
          dataSelect.escola = localStorage.getItem('escolas');
        }
      }
      axios.post(path() + "/graph/", {
        dataSelect,
        dataRoute
      }).then((result) => {
        if (result.data) {
          if (!result.data.error) {
            let grafico_visao_geral_agrupado = result.data.grafico_visao_geral_agrupado;
            for (let i = 0; i < grafico_visao_geral_agrupado.length; i++) {
              grafico_visao_geral_agrupado[i].key = i + 1;
            }
            let grafico_visao_geral_empilhado = result.data.grafico_visao_geral_empilhado;
            for (let i = 0; i < grafico_visao_geral_empilhado.length; i++) {
              grafico_visao_geral_empilhado[i].key = i + 1;
            }
            let tabela_visao_geral = result.data.tabela_visao_geral;
            for (let i = 0; i < tabela_visao_geral.length; i++) {
              tabela_visao_geral[i].key = i + 1;
            }
            let allCat = [
              '1º ano EF',
              '2º ano EF',
              '3º ano EF',
              '4º ano EF',
              '5º ano EF',
              '6º ano EF',
              '7º ano EF',
              '8º ano EF',
              '9º ano EF',
              '1º ano EM',
              '2º ano EM',
              '3º ano EM'
            ]
            var cat = [...new Set(grafico_visao_geral_agrupado.series.flatMap(({ data }) => data.map(({ name }) => name)))].sort();
            allCat = allCat.filter(value => cat.includes(value));
            grafico_visao_geral_agrupado.xAxis['categories'] = allCat;
            grafico_visao_geral_empilhado.xAxis['categories'] = allCat;
            this.setState({
              grafico_visao_geral_agrupado,
              grafico_visao_geral_empilhado,
              tabela_visao_geral,
              no_data: false,
              error: false
            })
          } else {
            this.setState({
              no_data: true,
              error: true
            })
          }
        } else {
          this.setState({
            no_data: true,
            error: false
          })
        }
      }).catch((err) => {
        this.setState({
          no_data: true,
          error: true
        })
      })
      this.props.graphSendUpdate(false);
    }
  }

  showError = () => {
    return (
      <Row>
        <Col span={24}>
          <Card>
            {
              this.state.error ?
                <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <i className="icon icon-error" style={{ fontSize: 32 }}></i>
                  <p>Nenhum resultado encontrado, por favor selecione novos dados.</p>
                </Row>
                :
                <Row style={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                  <i className="icon icon-alert" style={{ fontSize: 32 }}></i>
                  <p>Por favor, selecione os dados a serem pesquisados.</p>
                </Row>
            }
          </Card>
        </Col>
      </Row>
    )
  }

  showGraphs = () => {
    const Column = Table.Column;
    return (
      <Row>
        <Col span={24}>
          <Card >
            <RowTitle text="Pontuações" />
            <Row>
              <Col style={{ width: '100%' }}>
                <Table dataSource={this.state.tabela_visao_geral}
                  pagination={{ pageSize: 12 }}>
                  <Column
                    title="Alunos Avaliados"
                    dataIndex="Alunos Avaliados"
                    key="Alunos Avaliados"
                  />
                  <Column
                    title="Ano/Série"
                    dataIndex="Ano/Série"
                    key="Ano/Série"
                  />
                  <Column
                    title="Nota Média"
                    dataIndex="Pontuação Média"
                    key="Pontuação Média"
                  />
                  <Column
                    title="Desvio padrão"
                    dataIndex="Desvio-padrão"
                    key="Desvio-padrão"
                  />
                  <Column
                    title="Maior Nota"
                    dataIndex="Pontuação Máxima"
                    key="Pontuação Máxima"
                  />
                  <Column
                    title="Menor Nota"
                    dataIndex="Pontuação Mínima"
                    key="Pontuação Mínima"
                  />
                </Table>
                <div
                  onClick={() => {
                    return download(this.state.tabela_visao_geral, "Médias_de_Proficiência")
                  }}
                  className="ant-btn ant-btn-primary invisible">
                  Download
                </div>
              </Col>
              <ChartInfo text="Essa tabela relaciona, para cada ano/série avaliado, o número total de alunos, a pontuação média aferida, o desvio-padrão (que dá uma ideia da dispersão dos valores) e o valor da mínima e máxima pontuação observadas." />
            </Row>
          </Card>
        </Col>
        <Col span={24}>
          <Card >
            <RowTitle title="Alunos por Nível de Proficiência" />
            <Row>
              <Col style={{ width: '100%' }}>
                <HighchartsReact
                  style={{ width: '100%' }}
                  highcharts={Highcharts}
                  options={this.state.grafico_visao_geral_agrupado}
                />
              </Col>
              <ChartInfo text="Nesse gráfico, os anos/séries estão dispostos no eixo horizontal,
                            e o eixo vertical mostra o número de alunos. Para cada ano/série,
                            cada cor de barra representa um nível de proficiência,
                            e quanto mais alta a barra, mais alunos estão associados a esse
                            nível." />
              <Col style={{ width: '100%' }}>
                <HighchartsReact
                  style={{ width: '100%' }}
                  highcharts={Highcharts}
                  options={this.state.grafico_visao_geral_empilhado}
                />
              </Col>
              <ChartInfo text="Nesse gráfico, os anos/séries estão dispostos no eixo horizontal, e o eixo vertical mostra o número de alunos. Para cada ano/série, cada cor de barra representa um nível de proficiência, que pode ser acessado pelo menu de <a href='./downloads/'>Downloads</a>." />
            </Row>
          </Card>
        </Col>
      </Row>
    )
  }

  render() {
    return (
      <div>
        <PageHeader title="Visão Geral"
          text="Um olhar de larga escala que permite&nbsp;visualizar todos os anos/séries comparativamente"
          icon="icon-dasbhoard" />
        {
          this.state.no_data ?
            this.showError() :
            this.showGraphs()
        }
      </div>
    );
  };
}

const mapStateToProps = ({ graph }) => {
  const { dataRoute, dataSelect, dataFilter, updateGraphs } = graph;
  return { dataRoute, dataSelect, dataFilter, updateGraphs };
};

export default connect(mapStateToProps, {
  graphChangeRoute,
  graphChangeSelect,
  graphChangeFilter,
  graphSendUpdate
})(visaoGeral)