import React from "react";
import {Button, Checkbox, Form, Icon, Input, message} from "antd";
import {connect} from "react-redux";
import {Link} from "react-router-dom";

import {
  hideMessage,
  showAuthLoader,
  userSignIn
} from "appRedux/actions/Auth";
import IntlMessages from "util/IntlMessages";
import CircularProgress from "components/CircularProgress/index";

const FormItem = Form.Item;

class SignIn extends React.Component {

  componentDidUpdate() {
    if (this.props.showMessage) {
      setTimeout(() => {
        this.props.hideMessage();
      }, 100);
    }
    if (this.props.authUser !== null) {
      this.props.history.push('/');
    }
  }

  handleSubmit = (e) => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.showAuthLoader();
        this.props.userSignIn(values);
      }
    });
  };

  render() {
    const {getFieldDecorator} = this.props.form;
    const {showMessage, loader, alertMessage} = this.props;

    return (
      <div className="gx-app-login-wrap" 
        style={{
          display: 'flex', 
          justifyContent: 'space-between', 
          flexDirection: 'row'
        }}>
        <div style={{width: '50%'}}>
          <div className="gx-app-bg-login"></div>
        </div>
        
        <div className="gx-app-login-container" style={{width: 300, marginTop: 'auto', marginBottom: 'auto'}}>
          <div className="gx-app-login-main-content">
            <div className="gx-app-bg-logo" style={{ backgroundImage: 'url("' + (window._env_.LOGO_IMG || 'https://place-hold.it/180x60') + '")' }}></div>
            <div className="gx-app-login-content" style={{width: "100%", padding: 35}}>
              <Form onSubmit={this.handleSubmit} className="gx-signin-form gx-form-row0">
                <h1 style={
                  {
                    fontSize: "2em",
                    textAlign: "center"
                  }
                }>
                  Cockpit Laplace
                </h1>
                <FormItem>
                  {getFieldDecorator('email', {
                    rules: [{
                      required: true, type: 'email', message: 'Informe um email válido!',
                    }],
                  })(
                    <Input placeholder="seuemail@email.com"/>
                  )}
                </FormItem>
                <FormItem>
                  {getFieldDecorator('password', {
                    rules: [{required: true, message: 'Por favor, insira sua senha'}],
                  })(
                    <Input type="password" placeholder="Password"/>
                  )}
                </FormItem>
                <FormItem style={
                  {
                    display: "flex",
                    justifyContent: "flex-end"
                  }
                }>
                  <Button type="primary" className="gx-mb-0" htmlType="submit">
                    <IntlMessages id="app.userAuth.signIn"/>
                  </Button>
                  {}
                </FormItem>
              </Form>
            </div>

            {loader ?
              <div className="gx-loader-view">
                <CircularProgress/>
              </div> : null}
            {showMessage ?
              message.error(alertMessage.toString()) : null}
          </div>
        </div>
      </div>
    );
  }
}

const WrappedNormalLoginForm = Form.create()(SignIn);

const mapStateToProps = ({auth}) => {
  const {loader, alertMessage, showMessage, authUser} = auth;
  return {loader, alertMessage, showMessage, authUser}
};

export default connect(mapStateToProps, {
  userSignIn,
  hideMessage,
  showAuthLoader
})(WrappedNormalLoginForm);
