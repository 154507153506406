import {tableToXls} from '../requestEvents'

export const download = (jsonTable, filename) => {
  tableToXls(jsonTable, filename)
    .then(result => {
      var url = window.URL.createObjectURL(result.data)
      window.open(url);
      console.log(result);
    }).catch(err => {
      console.error(err);
    })
}