import React from "react";
import { Select, Button } from 'antd';
import {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
  } from "appRedux/actions/Graph";

import {connect} from "react-redux";


class DefaultDataSelect extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            ava: this.props.dataSelect.ava,
            disc: this.props.dataSelect.disc,
            as: this.props.dataSelect.as,
            escola: this.props.dataSelect.escola,
            rede: this.props.dataSelect.rede
        }
    }

    sendData = () => {
        this.props.graphChangeSelect({
            ...this.state,
            escola: this.props.dataSelect.escola,
            rede: this.props.dataSelect.rede
        });
        this.props.graphSendUpdate(true);
    }

    selectData = (select, value) => {
        switch(select) {
            case "disciplina": {
                this.setState({
                    disc: value
                })
                break;
            }
            case "ano": {
                this.setState({
                    ava: value
                })
                break;
            }
            case "serie": {
                this.setState({
                    as: value
                })
                break;
            }
        }
    }

    render() {
        const Option = Select.Option;
        return (
            <div style={{display: 'flex'}}>
                <Select
                    showSearch
                    onChange={(value) => {this.selectData("ano", value)}}
                    style={{ width: 125, marginRight: 20, display: 'none' }}
                    placeholder="Ano">
                    <Option value="DD001">2019</Option>
                </Select>
                <Select
                    showSearch
                    onChange={(value) => {this.selectData("disciplina", value)}}
                    style={{ width: 125, marginRight: 20 }}
                    placeholder="Disciplina">
                {
                    window.disciplines.map((item, index) => {
                        const isDuplicate = disciplines.slice(0, index).some((t) => t.code === item.code);
                        if (isDuplicate) {
                            return null;
                        }
                        return (
                            <Option key={item.code} value={item.code}>
                                {item.name}
                            </Option>
                        );
                    })
                }
                </Select>
                <Select
                    showSearch
                    onChange={(value) => {this.selectData("serie", value)}}
                    style={{ width: 125, marginRight: 20 }}
                    placeholder="Ano/Série">
                {
                    window.grades.map((item) => {
                        return (
                            <Option key={item.name} value={item.name}>
                                {item.name}
                            </Option>
                        );
                    })
                }
                </Select>
                <Button type="primary" onClick={this.sendData}>
                    Selecionar dados
                </Button>
            </div>
        );
    }
}

const mapStateToProps = ({ graph }) => {
    const {dataRoute, dataSelect, dataFilter, updateGraphs} = graph;
    return {dataRoute, dataSelect, dataFilter, updateGraphs};
};

export default connect(mapStateToProps, {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
})(DefaultDataSelect)