import {
    GRAPH_ROUTE,
    GRAPH_SELECT,
    GRAPH_FILTER,
    GRAPH_UPDATE,
    GRAPH_CLEAR
  } from "../../constants/ActionTypes";


const INIT_STATE = {
    dataRoute: '', 
    dataSelect: {ava: '', disc: '', as: '', escola: null, rede: null}, 
    dataFilter: null,
    updateGraphs: false
}

export default (state = INIT_STATE, action) => {
    switch (action.type){
        case GRAPH_ROUTE: {
            return {
                ...state,
                dataRoute: action.payload
            }
        }
        case GRAPH_SELECT: {
            return {
                ...state,
                dataSelect: {...action.payload}
            }
        }
        case GRAPH_FILTER: {
            return {
                ...state,
                dataSelect: action.payload
            }
        }
        case GRAPH_UPDATE: {
            return {
                ...state,
                updateGraphs: action.payload
            }
        }
        case GRAPH_CLEAR: {
            return {
                ...INIT_STATE
            }
        }
        default: {
            return state
        }
    }
}