import React, { Component } from "react";
import { connect } from "react-redux";
import { Menu, Select } from "antd";
import { Link } from "react-router-dom";

import CustomScrollbars from "util/CustomScrollbars";
import SidebarLogo from "./SidebarLogo";
import IntlMessages from "util/IntlMessages";
import { NAV_STYLE_MINI, THEME_TYPE_LITE } from "constants/ThemeSetting";
import Auxiliary from "util/Auxiliary";

const SubMenu = Menu.SubMenu;
const MenuItemGroup = Menu.ItemGroup;

class SidebarContent extends Component {

  superAdminItemGroup = (userType) => {
    if (userType === 'SUPER_ADMIN') {
      return (
        <MenuItemGroup key="admin" title="Admin">
          <Menu.Item key="/5">
            <Link to={`/manager/userList`}>
              <i className="icon icon-auth-screen" />
              <span>Gerência de usuários</span>
            </Link>
          </Menu.Item>
        </MenuItemGroup>
      )
    }
  }

  render() {
    const { navStyle, themeType, verticalNavStyle, pathname, userType } = this.props;
    const selectedKeys = (pathname || '').substr(1);
    const defaultOpenKeys = selectedKeys.split('/')[1];

    return (<Auxiliary>

      <SidebarLogo />
      <div className="logo" style={{ backgroundImage: 'url("' + (window._env_.LOGO_IMG || 'https://place-hold.it/180x60') + '")' }}>

      </div>
      <CustomScrollbars className="gx-layout-sider-scrollbar">
        <Menu
          defaultOpenKeys={[defaultOpenKeys]}
          selectedKeys={[selectedKeys]}
          //inlineCollapsed={verticalNavStyle === NAV_STYLE_MINI}
          theme={themeType === THEME_TYPE_LITE ? 'lite' : 'dark'}
          mode="inline">
          <MenuItemGroup key="main" title="Avaliações">
            <Menu.Item key="/avaliacoes/visao-geral">
              <Link to={`/avaliacoes/visao-geral`}>
                <i className="icon icon-dasbhoard" />
                <span>Visão Geral</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/avaliacoes/medias-faixas">
              <Link to={`/avaliacoes/medias-faixas`}>
                <i className="icon icon-data-display" />
                <span>Médias e Faixas</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/avaliacoes/matrizes">
              <Link to={`/avaliacoes/matrizes`}>
                <i className="icon icon-feedback" />
                <span>Matriz de Referência</span>
              </Link>
            </Menu.Item>
            <Menu.Item disabled="true" key="/avaliacoes/analise-itens">
              <Link to={`/avaliacoes/analise-itens`}>
                <i className="icon icon-area-chart" />
                <span>Análise de Itens</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/avaliacoes/consulta-alunos">
              <Link to={`/avaliacoes/consulta-alunos`}>
                <i className="icon icon-table" />
                <span>Consulta de Alunos</span>
              </Link>
            </Menu.Item>
            <Menu.Item key="/1">
              <Link to={`/avaliacoes/downloads`}>
                <i className="icon icon-upload" />
                <span>Downloads</span>
              </Link>
            </Menu.Item>
          </MenuItemGroup>

          <MenuItemGroup key="rede" title="Rede de escolas">
            <Menu.Item disabled="true" key="/4">
              <Link to={`/static/redes`}>
                <i className="icon icon-chart-tree" />
                <span>Visualizar Rede</span>
              </Link>
            </Menu.Item>
          </MenuItemGroup>

          {
            this.superAdminItemGroup(userType)
          }

        </Menu>
      </CustomScrollbars>
    </Auxiliary>
    );
  }
}

SidebarContent.propTypes = {};
const mapStateToProps = ({ settings, auth }) => {
  const { navStyle, verticalNavStyle, themeType, locale, pathname } = settings;
  const { userType } = auth;
  return { navStyle, verticalNavStyle, themeType, locale, pathname, userType }
};
export default connect(mapStateToProps)(SidebarContent);

