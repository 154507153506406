import React from "react";
import {Card, Col, Row, Table, Icon} from "antd";


export default class rowTitle extends React.Component {
    constructor(props){
        super(props);
        this.state = {
            text: props.text
        }
    }  

    render() {

        return (
            <Row>
              <h1 className="row-title">
                {this.state.text}
              </h1>
            </Row>
        ) 
    }
}