import React from "react";
import { withRouter } from "react-router";
import DefaultDataSelect from './defaultDataSelect'
import VisaoGeralDataSelect from './visaoGeralDataSelect'
import ConsultaAlunosRedeEscolaDataSelect from './consultaAlunosRedeEscolaDataSelect'
import {getUserType} from 'util/decoders'

class DataSelect extends React.Component {

    constructor(props) {
        super(props);
        this.state = {
            path: window.location.pathname,
            userType: getUserType()
        }
    }

    componentDidMount() {
        this.unlisten = this.props.history.listen((location, action) => {
            this.setState({
                path: location.pathname
            })
        });
    }

    componentWillUnmount() {
        this.unlisten();
    }

    render() {
        if(this.state.path === '/avaliacoes/visao-geral'){
            return <VisaoGeralDataSelect />
        }else if(this.state.path === '/avaliacoes/consulta-alunos') {
            return <ConsultaAlunosRedeEscolaDataSelect />
        } else {
            return <DefaultDataSelect />
        }
    }
}
export default withRouter(DataSelect);