export const getUserRedes = () => {
    if (localStorage.getItem('redes'))
        return JSON.parse(localStorage.getItem('redes'))
    return []
}

export const getUserEscolas = () => {
    if (localStorage.getItem('escolas'))
        return localStorage.getItem('escolas').split(',')
    return []
}