import {combineReducers} from "redux";
import { connectRouter } from 'connected-react-router'
import Settings from "./Settings";
import Auth from "./Auth";
import Graph from "./Graph";


const createRootReducer = (history) => combineReducers({
  router: connectRouter(history),
  settings: Settings,
  auth: Auth,
  graph: Graph 
});

export default createRootReducer;
