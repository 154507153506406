import React from "react";
import Auxiliary from "util/Auxiliary";
import { Select, Button } from 'antd';

import {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
} from "appRedux/actions/Graph";

import { getUserRedes, getUserEscolas } from 'util/storage';

import { connect } from "react-redux";

class AppFilter extends React.Component {
    constructor(props) {
        super(props);

        let rede = undefined;
        let escola = undefined;

        let redesReadOnly = false;
        let escolaReadOnly = false;
        let filterReadOnly = false;

        if (localStorage.getItem('redes')) {
            if (JSON.parse(localStorage.getItem('redes').split(",")).length < 2) {
                rede = JSON.parse(localStorage.getItem('redes'))[0].rede;
                redesReadOnly = true;
            }
        }

        if (localStorage.getItem('escolas')) {
            if (localStorage.getItem('escolas').split(",").length < 2) {
                escola = localStorage.getItem('escolas');
                escolaReadOnly = true;
            }
        }

        if (redesReadOnly && escolaReadOnly) {
            filterReadOnly = true;
        }

        this.state = {
            rede: rede,
            escola: escola,
            redesReadOnly: redesReadOnly,
            escolaReadOnly: escolaReadOnly,
            filterReadOnly: filterReadOnly
        }
    }

    sendData = () => {

        this.props.graphChangeSelect({
            ava: this.props.dataSelect.ava,
            disc: this.props.dataSelect.disc,
            as: this.props.dataSelect.as,
            rede: this.state.rede,
            escola: this.state.escola
        });
        this.props.graphSendUpdate(true);

    }

    clearData = () => {
        if (!this.state.redesReadOnly) {
            this.setState({
                rede: undefined
            });
        }
        if (!this.state.escolaReadOnly) {
            this.setState({
                escola: undefined
            });
        }
        this.sendData();
    }
    selectEscola = (escola) => {
        this.setState({ escola });
    }

    selectRede = (rede) => {
        this.setState({ rede });
    }

    renderEscolasByRede = (redeSelecionada) => {
        const redeObj = getUserRedes().filter(rede => rede.rede === redeSelecionada)
        const userEscolas = getUserEscolas();
        const escolasDisponiveis = redeObj.reduce((escolas, rede) => {
            rede.escolas.forEach((escola) => {
                if (userEscolas.indexOf(escola.key) >= 0)
                    escolas.push(escola);
            })
            return escolas
        }, [])
        const Option = Select.Option;
        return (
            <Select
                showSearch
                style={{ width: 200, marginBottom: 20 }}
                value={this.state.escola}
                onChange={(value) => { this.selectEscola(value) }}
                placeholder="Escola"
                disabled={this.state.escolaReadOnly}>
                {
                    escolasDisponiveis.filter((escola, index) => {
                        return index === escolasDisponiveis.findIndex(obj => obj.key === escola.key);
                    }).map((escola, index) => {
                        return (
                            <Option value={escola.key} key={index}>
                                {escola.key}
                            </Option>
                        );
                    })
                }
            </Select>
        )
    }

    render() {
        const Option = Select.Option;
        return (
            <Auxiliary>
                <div className="gx-popover-header">
                    <h3 className="gx-mb-0">Filtro</h3>
                </div>
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Select
                        showSearch
                        style={{ width: 200, marginBottom: 20, marginTop: 20 }}
                        value={this.state.rede}
                        onChange={(value) => { this.selectRede(value) }}
                        placeholder="Rede de escolas"
                        disabled={this.state.redesReadOnly}>
                        {
                            getUserRedes().map((rede, index) => {
                                const isDuplicate = getUserRedes().slice(0, index).some((r) => r.rede === rede.rede);
                                if (isDuplicate) {
                                    return null;
                                }
                                return (
                                    <Option key={rede.rede} value={rede.rede}>
                                        {rede.rede.charAt(0).toUpperCase() + rede.rede.slice(1)}
                                    </Option>
                                );
                            })
                        }
                    </Select>
                    {
                        this.state.rede ?
                            this.renderEscolasByRede(this.state.rede) :
                            null
                    }

                    <div
                        style={{ display: 'flex', width: '100%', justifyContent: 'space-between' }}
                    >
                        <Button disabled={this.state.filterReadOnly}
                            type="primary"
                            onClick={this.sendData}
                        >
                            Aplicar
                        </Button>

                        <Button disabled={this.state.filterReadOnly}
                            onClick={this.clearData}
                        >
                            Limpar
                        </Button>
                    </div>
                </div>
            </Auxiliary>
        )
    }
}

const mapStateToProps = ({ graph }) => {
    const { dataRoute, dataSelect, dataFilter, updateGraphs } = graph;
    return { dataRoute, dataSelect, dataFilter, updateGraphs };
};

export default connect(mapStateToProps, {
    graphChangeRoute,
    graphChangeSelect,
    graphChangeFilter,
    graphSendUpdate
})(AppFilter)